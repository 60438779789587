import React, { useContext, useEffect, useState } from "react";
import AppContext from "../utils/app-context";
import StoryPartForm from "../components/story-part-form";
import { useHistory } from "react-router-dom";
import firebase, {analytics} from "../utils/firebase.js";
import ConfirmReplacementDialog from "../components/confirm-replacement-dialog"

import { Typography, Box, Input, Button, FormControl, Container } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Unstable_Grid2'
import Item from '@mui/material/Unstable_Grid2'
import Headbar from "./Headbar";
import Footbar from "./Footbar";
import theme from '../styles/theme'


function SettingsInspector() {

  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();

  const story = state.stories.activeStory
  const settings = state.app.settings 
  const isOwner = state.app.userIsStoryOwner; 
  const handleDone = () => {
    history.goBack();
  }

  const updateSetting = (new_settings) => {
    dispatch({ type: "UPDATE_STORY_SETTINGS", payload: { settings: new_settings } });
  }

  const testSettings = () => {
  const new_settings = {"promptsVisible": (!settings.promptsVisible)}
      updateSetting(new_settings)
  }  

  const toggleSetting = (setting_key) => {
      if (isOwner) {
      // console.log("TOGGLE KEY", setting_key)
      const new_settings = Object.create(null)
      new_settings[setting_key] = !settings[setting_key]
      // console.log("THESE NEW SETTINGS", new_settings)
      updateSetting(new_settings)
    }  
  }
  const renderSettings = () => (
    <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: '20px', overflow: 'hidden' }}>
      <Box sx={{ maxWidth: '550px', width: '100%', boxSizing: 'border-box' }}>
        <Typography variant="brand" sx={{ marginBottom: '15px' }}>
          Current Story Settings
        </Typography>
        {story.title && (
          <Typography
            variant="h3"
            sx={{ marginBottom: '25px', textAlign: 'center', fontWeight: 'bold' }}
          >
            {story.title}
          </Typography>
        )}
        {Object.entries(settings).map(([settingKey]) => (
          <Grid
            container
            key={settingKey}
            spacing={2}
            sx={{
              alignItems: 'center',
              marginBottom: '15px',
              border: '1px solid #f6f6f6',
              borderRadius: '5px',
              padding: '10px',
              width: '100%', 
              boxSizing: 'border-box', 
              justifyContent:'space-between'
            }}
          >
            <Grid item xs={12} sm={6} sx={{ boxSizing: 'border-box' }}>
              <Typography variant="body2" sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                {settingKey}
              </Typography>
            </Grid>
            
            <Grid item xs={6} sm={3} sx={{ boxSizing: 'border-box' }}>
              <Button
                onClick={() => toggleSetting(settingKey)}
                sx={{
                  minWidth: '60px',
                  display: 'block',
                  margin: { xs: '10px auto 0', sm: '0 auto' },
                  width: 'auto',
                  boxSizing: 'border-box',
                  textTransform:'capitalize'
                }}
              >
                 {settings[settingKey] ? 'True' : 'False'}
              </Button>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
  
const renderSummary = () => (
  <Box sx={{ textAlign: 'center', padding: '20px' }}>
    <Typography variant="h6" sx={{ marginBottom: "15px" }}>Permissions Summary</Typography>
    <Box>
      {(() => {
        const trueSettings = Object.entries(settings)
          .filter(([, settingValue]) => settingValue === true)
          .map(([settingKey]) => settingKey);

        return trueSettings.length > 0 ? (
          <Typography variant="body2" sx={{ marginBottom: "10px" }}>
            {trueSettings.join(", ")}
          </Typography>
        ) : (
          <Typography variant="body2" sx={{ marginBottom: "10px" }}>
            No permissions granted.
          </Typography>
        );
      })()}
    </Box>
  </Box>
);
const renderNoStory = () => (
  <Box sx={{ textAlign: "center", padding: "20px" }}>
    <Typography variant="h6" sx={{ marginBottom: "15px" }}>
      Settings Not Available
    </Typography>
    <Typography variant="body2" sx={{ marginBottom: "10px" }}>
      Settings not available or no active storyboard
    </Typography>
  </Box>
);


    const cancelBtn = {click: handleDone, type:"tertiary", text:"Cancel"}
    const saveBtn = {click: handleDone, type:"primary", text:"Save"}

    const renderStorySettingsContent = () => {
      if (!story || story.deleted_at) {
        return renderNoStory();
      }
      return isOwner ? renderSettings() : renderSummary();
    };
    
    

    return( 
      <Container className="has-footbar" style={{ maxWidth:"100%", paddingLeft:0, paddingRight:0 }}>
        <Headbar title={"Storyboard Settings"} breadcrumbs={false} />
        <Box className="app_container">
          {renderStorySettingsContent()}
        </Box>
        <Footbar buttons={[cancelBtn, saveBtn]} /> 
      </Container>
    )
}

export default SettingsInspector
