
// note:  once deployed, some of this may be accomplished from document.URL ... but not the localhost scenario...

const okvHostConfig = {
    "app_url": "https://okapi-webdev.web.app",
    "home_url": "https:okapivideo.com",
    "storage_api": "https://storage.googleapis.com/v0zebra-dev.appspot.com", 
    "env": "okv", 
    "sample_story":"E4VoR1rfpfKk"
};

const prod10zHostConfig = {
    "app_url": "https://10zebra.app",
    "home_url": "https://10zebra.com",  
    "storage_api": "https://storage.googleapis.com/v0zebra.appspot.com",
    "env": "prod", 
    "sample_story":"E4VoR1rfpfKk"
};
const dev10zHostConfig = {
    "app_url": "https://10zebra.dev",
    "home_url": "https://10zebra.com",
    "storage_api": "https://storage.googleapis.com/v0zebra-dev.appspot.com",
    "env": "dev", 
    "sample_story": "c-ZMAjRyDYEK"
};
const rnbwHostConfig = {
    "app_url": "https://rnbw.art",
    "home_url": "https://rnbw.art",
    "storage_api": "https://storage.googleapis.com/v0zebra-dev.appspot.com",
    "env": "rnbw", 
    "sample_story":"E4VoR1rfpfKk"
};
const aiModels = new Map();

if(process.env.REACT_APP_FIREBASE_ENV != 'prod') {
  // display name in app drop down --> fields stored in backend: method_name,method,engine 
    // logic:  user might use method name as a display field for what it is, informative simple uuid
    //         backend tools may use engine name to run it appropriately - generally model type
    //         method can be used to split different types - e.g. different task on similar engine
    
    // name, key, method_name, method, engine, desc
  // aiModels.set('vid2vid-v0', {name:'Video to Video Zero', method_name:'vid2vid-v0', method:'vid2vid', engine:'cerspense/zeroscope_v2_576w', desc:'A video to video model'});
  // aiModels.set('vid2vid-2', {name:'Video to Video Haiper', method_name:'haiper-video', method:'vid2vid', engine:'haiper-v2', desc:'Video to video with haiper'});

  //aiModels.set('haiper-image', {name:'1st Frame Haiper', method:'vid2vid', engine:'haiper-v2', desc:'Animate the first frame of your video.', cost: 20});
  //aiModels.set('haiper-image-last', {name:'Last Frame Haiper', method_name:'haiper-image-last', method:'vid2vid', engine:'haiper-v2', desc:'Animate from the last frame of your video.', cost: 20});
  aiModels.set('luma-image', {name:'1st Frame Luma', method:'vid2vid', engine:'luma-v1_6', desc:'Animate the first frame of your video.', cost: 40});
  aiModels.set('runway-image', {name:'1st Frame Runway', method:'vid2vid', engine:'runway-gen3a', desc:'Animate the first frame of your video.', cost: 40});
  aiModels.set('minimax-image', {name:'1st Frame Minimax', method:'vid2vid', engine:'minimax-01', desc:'Animate the first frame of your video.', cost: 60});
  aiModels.set('minimax-image-live', {name:'1st Frame Minimax Animate', method:'vid2vid', engine:'minimax-01-live', desc:'Animate the first frame of your video.', cost: 60});
  aiModels.set('comfy-FFAutoCine-v0', {name:'1st Frame AutoCine', method:'vid2vid', engine:'comfy-FFAutoCine-v0', desc:'Animate the first frame of your video.', cost: 20});
  aiModels.set('kipalum-tween-style', {name:'Style Tween (Precise)', method:'vid2vid', engine:'kipalum-tween-style', desc:'Re-style and tween from first to last frame.', cost: 60});
  aiModels.set('kipalum-tween-composition', {name:'Style Tween (Compose)', method:'vid2vid', engine:'kipalum-tween-composition', desc:'Borrow source composition and tween from first to last frame.', cost: 60});
  aiModels.set('kipalum-tween-bold', {name:'Style Tween (Bold)', method:'vid2vid', engine:'kipalum-tween-bold', desc:'Re-style and tween from first to last frame.', cost: 60});


  // aiModels.set('im2im', {name:'IM to IM', method_name:'im2im-v4', method:'im2im', engine:'stabilityai/stable-diffusion-2-depth', desc:'A IM to IM model', cost: 100});
  // aiModels.set('text2vid-v0', {name:'Zero', method_name:'text2vid-v0', method:'text2vid', engine:'cerspense/zeroscope_v2_576w', desc:'A text to video model', cost: 100});
  // aiModels.set('haiper-text', {name:'Haiper',  method:'text2vid', engine:'haiper-v2', desc:'text to video with haiper', cost: 20});
  // aiModels.set('text2vid-flyper', {name:'Flyper', method:'text2vid', engine:'black-forest-labs/flux-pro', desc:'Flux images to haiper videos', cost: 20});
  aiModels.set('text2vid-fluma', {name:'Fluma', method:'text2vid', engine:'black-forest-labs/flux-pro', desc:'Flux images to luma videos', cost: 40});
  aiModels.set('text2vid-funway', {name:'Funway', method:'text2vid', engine:'black-forest-labs/flux-pro', desc:'Flux images to runway videos', cost: 40});
  aiModels.set('text2vid-imaway', {name:'Imaway', method:'text2vid', engine:'imagen-3', desc:'Imagen images to runway videos', cost: 40, composeModel: true});
  aiModels.set('text2vid-bang', {name:'Bang', method:'text2vid', engine:'imagen-3', desc:'Imagen images to minimax videos', cost: 60, composeModel: true});
  aiModels.set('luma2', {name:'Ray-2', method:'text2vid', engine:'luma-v2', desc:'Luma Ray-2 videos ay 720p', cost: 70});
  //aiModels.set('luma2-1080', {name:'Ray2 1080', method:'text2vid', engine:'luma-v2', desc:'Luma Ray-2 videos at 1080p', cost: 170});
  // aiModels.set('text2vid-runway', {name:'Runway', method:'text2vid', engine:'runway/gen3a', desc:'Text to video using Runway', cost: 40});
  // aiModels.set('text2vid-flyper-realism', {name:'F : Realism', method:'text2vid', engine:'philipmeier/flux-dev-realism', desc:'Flux images to haiper videos', cost: 30});
  // aiModels.set('text2vid-flyper-retro', {name:'F : Retro', method:'text2vid', engine:'philipmeier/flux-kodachrome', desc:'Flux images to haiper videos', cost: 30});
  aiModels.set('text2vid-flenburns', {name:'One Image', method:'text2vid', engine:'black-forest-labs/flux-pro', desc:'Flux images kenburnsed', cost: 10});
  aiModels.set('text2vid-flenburns', {name:'One Image', method:'text2vid', engine:'black-forest-labs/flux-pro', desc:'Flux images kenburnsed', cost: 10});
  // aiModels.set('four-schnell', {name:'Four Images', method:'text2vid', engine:'four-schnell', desc:'Four fast images kenburnsed', cost: 20});
  aiModels.set('four-imagen', {name:'Four Images', method:'text2vid', engine:'four-imagen', desc:'Four images kenburnsed', cost: 20, composeImageModel: true});

} else {
  // PROD
  // name, key, method_name, method, engine, desc
  // aiModels.set('vid2vid-v0', {name:'Video to Video Zero', method_name:'vid2vid-v0', method:'vid2vid', engine:'cerspense/zeroscope_v2_576w', desc:'A video to video model'});
  // aiModels.set('vid2vid-2', {name:'Video to Video Haiper', method_name:'haiper-video', method:'vid2vid', engine:'haiper-v2', desc:'Video to video with haiper'});

  //aiModels.set('haiper-image', {name:'1st Frame Haiper', method:'vid2vid', engine:'haiper-v2', desc:'Animate the first frame of your video.', cost: 20});
  //aiModels.set('haiper-image-last', {name:'Last Frame Haiper', method_name:'haiper-image-last', method:'vid2vid', engine:'haiper-v2', desc:'Animate from the last frame of your video.', cost: 20});
  aiModels.set('luma-image', {name:'1st Frame Luma', method:'vid2vid', engine:'luma-v1_6', desc:'Animate the first frame of your video.', cost: 40});
  aiModels.set('runway-image', {name:'1st Frame Runway', method:'vid2vid', engine:'runway-gen3a', desc:'Animate the first frame of your video.', cost: 40});
  aiModels.set('minimax-image', {name:'1st Frame Minimax', method:'vid2vid', engine:'minimax-01', desc:'Animate the first frame of your video.', cost: 60});
  aiModels.set('minimax-image-live', {name:'1st Frame Minimax Animate', method:'vid2vid', engine:'minimax-01-live', desc:'Animate the first frame of your video.', cost: 60});
  aiModels.set('comfy-FFAutoCine-v0', {name:'1st Frame AutoCine', method:'vid2vid', engine:'comfy-FFAutoCine-v0', desc:'Animate the first frame of your video.', cost: 20});
  aiModels.set('kipalum-tween-style', {name:'Style Tween (Precise)', method:'vid2vid', engine:'kipalum-tween-style', desc:'Re-style and tween from first to last frame.', cost: 60});
  aiModels.set('kipalum-tween-composition', {name:'Style Tween (Compose)', method:'vid2vid', engine:'kipalum-tween-composition', desc:'Borrow source composition and tween from first to last frame.', cost: 60});
  aiModels.set('kipalum-tween-bold', {name:'Style Tween (Bold)', method:'vid2vid', engine:'kipalum-tween-bold', desc:'Re-style and tween from first to last frame.', cost: 60});

  // aiModels.set('im2im', {name:'IM to IM', method_name:'im2im-v4', method:'im2im', engine:'stabilityai/stable-diffusion-2-depth', desc:'A IM to IM model', cost: 100});
  // aiModels.set('text2vid-v0', {name:'Zero', method_name:'text2vid-v0', method:'text2vid', engine:'cerspense/zeroscope_v2_576w', desc:'A text to video model', cost: 100});
  //aiModels.set('haiper-text', {name:'Haiper',  method:'text2vid', engine:'haiper-v2', desc:'text to video with haiper', cost: 20});
  //aiModels.set('text2vid-flyper', {name:'Flyper', method:'text2vid', engine:'black-forest-labs/flux-pro', desc:'Flux images to haiper videos', cost: 20});
  aiModels.set('text2vid-fluma', {name:'Fluma', method:'text2vid', engine:'black-forest-labs/flux-pro', desc:'Flux images to luma videos', cost: 40});
  aiModels.set('text2vid-funway', {name:'Funway', method:'text2vid', engine:'runway/gen3a', desc:'Flux images to runway videos', cost: 40});
  aiModels.set('text2vid-imaway', {name:'Imaway', method:'text2vid', engine:'runway/gen3a', desc:'Imagen images to runway videos', cost: 40, composeModel: true});
  aiModels.set('text2vid-bang', {name:'Bang', method:'text2vid', engine:'imagen-3', desc:'Imagen images to minimax videos', cost: 60, composeModel: true});
  aiModels.set('luma2', {name:'Ray-2', method:'text2vid', engine:'luma-v2', desc:'Luma Ray-2 videos ay 720p', cost: 70});
  // aiModels.set('luma2-1080', {name:'Ray2 1080', method:'text2vid', engine:'luma-v2', desc:'Luma Ray-2 videos at 1080p', cost: 170});

// aiModels.set('text2vid-runway', {name:'Runway', method:'text2vid', engine:'runway/gen3a', desc:'Text to video using Runway', cost: 40});
  // aiModels.set('text2vid-flyper-realism', {name:'F : Realism', method:'text2vid', engine:'philipmeier/flux-dev-realism', desc:'Flux images to haiper videos', cost: 30});
  //aiModels.set('text2vid-flyper-retro', {name:'F : Retro', method:'text2vid', engine:'philipmeier/flux-kodachrome', desc:'Flux images to haiper videos', cost: 30});
  aiModels.set('text2vid-flenburns', {name:'One Image', method:'text2vid', engine:'black-forest-labs/flux-pro', desc:'Flux images kenburnsed', cost: 10});
  // aiModels.set('four-schnell', {name:'Four Images', method:'text2vid', engine:'four-schnell', desc:'Four fast images kenburnsed', cost: 20, composeImageModel: true});
  aiModels.set('four-imagen', {name:'Four Images', method:'text2vid', engine:'four-imagen', desc:'Four images kenburnsed', cost: 20, composeImageModel: true});

}

let hostConfig = {}

switch(process.env.REACT_APP_FLAVOR) {
  case 'okv':
    hostConfig = okvHostConfig;
    break;
  case '10z':
    if (process.env.REACT_APP_FIREBASE_ENV === 'prod'){
        hostConfig = prod10zHostConfig;
    } else {
        hostConfig = dev10zHostConfig;
    }
    break;
  case 'rnbw':
    hostConfig = rnbwHostConfig;
    break;
  default:
    hostConfig = dev10zHostConfig;
}

hostConfig.aiModels = aiModels;
hostConfig.veneerCost = 100;

export default hostConfig;