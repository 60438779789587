import { Box, Button, Container, Tooltip, FormControl, RadioGroup, Radio, Input, Typography, Modal, Fade, Backdrop, TextField, Checkbox, FormControlLabel, Stack, Link, CircularProgress } from "@mui/material";
import { Warning } from "@mui/icons-material";
import React, { useContext, useState, useEffect, useRef } from "react";
import firebase, { analytics, db } from "../utils/firebase.js";

import AppContext from "../utils/app-context";
import { formatDate } from "../utils/utility.js";
import { nanoid } from "nanoid";
import theme from "../styles/theme";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import assets from "../utils/assets";
import DropUpload from "./drop-upload.js";
import hostConfig from "../utils/config";
import toast from "react-hot-toast";
import Messages from "../constants/app.js";
import { ArrowBack, Close } from "@mui/icons-material";
import { handleContinueEmpty } from "../helpers/index.js";
import Headbar from "./Headbar";

const wrapStyles = {
  maxWidth: "900px", 
  margin: "0 auto", 
  padding: "0 20px", 
  alignSelf: "center",
  [theme.breakpoints.down("md")]: {
    padding:0, 
  }
}

const newStyles = {
    width: "100%",
    height: "auto",
    margin: 0,
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "stretch", 
    [theme.breakpoints.down("md")]: {
      flexFlow: "row wrap", 
      justifyContent: "space-around"
    }
}
const formStyles = {
    width: "100%",
    maxWidth: "800px", 
    height: "auto",
    margin: 0,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "start", 
}
const colStyles ={
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "top",
    padding:"10px 15px 15px", 
    backgroundColor: theme.palette.secondary.light,
    minHeight:"150px", 
    marginRight: "10px", 
    maxWidth: "25%", 
    "&:last-child": {
      marginRight:"0", 
    }, 
    " .MuiRadio-colorPrimary": {
      width:"40px", 
      margin:"0 auto"
    }, 
    [theme.breakpoints.down("md")]: {
      marginBottom:"20px",
      maxWidth:"calc(50% - 40px)",
      "&:nth-child(2n)": {
        marginRight:"0", 
      }, 
    }
}
const iconStyles = {
  fontSize: "20px",
  lineHeight: "24px",
  marginRight: "5px",
  position: "relative",
  color: theme.palette.secondary.dark,
};

function NewProjectSelector() {
  const { state, dispatch } = useContext(AppContext);

  const story = state.stories.activeStory;
  const stories = state.stories.storyList;
  const history = useHistory();
  /*const [part1, setPart1] = useState(part1_init);
  const [part2, setPart2] = useState(part2_init);
  const [part3, setPart3] = useState(part3_init);*/
  const [urlValid, setUrlValid] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalStep, setModalStep] = useState('initial');
  const [description, setDescription] = useState('');
  const [importUrl, setImportUrl] = useState("https://www.youtube.com/watch?v=0nXB-Bgx1eM"); // travel thumnails
  const [generateMedia, setGenerateMedia] = React.useState(true);
  const [isStoryLoading, setIsStoryLoading] = useState(false)
  const [newSample, setNewSample] = useState(null);
  const [selectedNew, setSelectedNew] = useState("blank")
  const selectedMethod = useRef(null)
  const isProd = process.env.REACT_APP_FIREBASE_ENV == 'prod';
  let composeModel; 
  let composeImageModel;
  hostConfig.aiModels.forEach((model, key) => { 
    if(model.composeModel == true) { 
      composeModel = {...model, method_name: key};
    } 
    if(model.composeImageModel == true) {
      composeImageModel = {...model, method_name: key};
    }
  });
  const [chosenCompose, setChosenCompose] = React.useState(composeModel);
  const [generateCost, setGenerateCost] = useState(composeModel.cost + composeImageModel.cost)
  const handleGenerateMedia = (event) => {
    setGenerateMedia(event.target.checked);
    if(event.target.checked) {
      setChosenCompose(composeModel);
      setGenerateCost(composeModel.cost + composeImageModel.cost)
    } else { 
      setChosenCompose(composeImageModel);
      setGenerateCost(composeImageModel.cost)
    }
  };
  const cost_per_veneer = 10;
  const userRef = (Object.keys(state.userData).length) ? state.userData.ref : null;

  // default imported video
  //const [url, setUrl] = useState("https://www.youtube.com/watch?v=hKWMrSGO6RQ");
  //const [importUrl, setImportUrl] = useState("https://www.youtube.com/watch?v=hKWMrSGO6RQ");  // 2014 pasta vine
  //const [importUrl, setImportUrl] = useState("https://www.youtube.com/watch?v=Ktb44YLL8Nw");  // take on me ... fading lyrics dont trigger, needs other threshold


  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
    setModalStep('initial');
  };

  const handleStoryFromVideo = () => {
    setModalStep('video');
  };

  const handleStoryFromDescription = () => {
    setModalStep('description');
  };

  const handleBack = () => {
    setModalStep('initial');
  };

  const generate = {
    description: description,
    generateMedia: generateMedia,
    portal_id: nanoid(12),
    composeModel: composeModel, 
    composeImageModel: composeImageModel, 
    cost: 10 * generateCost // 10 generations * model cost
  }

  const handleGenerateStoryboard = async () => {   
    if(state.userData.credits - generate.cost < 0) {
      const err = "Not enough credits for generations";
      toast.error(err);
      return;
    }
    dispatch({ type: "GENERATE_STORY", payload: { "generateStory": generate } });
    toast.success(Messages.info.storyGenerationStarted)
    history.push("/p/" + generate.portal_id);
  }

  useEffect(() => {
    if(newSample && state.stories.activeStory.portal_id == newSample) {
      setNewSample(null);
      history.push('/p/'+state.stories.activeStory.portal_id)
    }
  }, [state.stories.activeStory, newSample])

  const getWarning = () => {
    if(state.userData.credits - generate.cost < 0) { 
      return "Not enough credits for generations"
    }
    return;
  }



  /*const handleGenerateStoryboard = async () => {
    setIsStoryLoading(true)
    const idToken = await firebase.auth().currentUser.getIdToken();
    //let local = 'http://127.0.0.1:5001/v0zebra-dev/us-central1/generateStoryboard'
    //let prod = 'https://us-central1-v0zebra-dev.cloudfunctions.net/generateStoryboard'
    const response = await fetch(prod, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${idToken}`
      },
      body: JSON.stringify({ storyDescription: description })
    });
    const result = await response.json();
    if (response.ok) {
      await createStory(result)
      console.log("Generated Storyboard ID:", result);
      setIsStoryLoading(false)
    } else {
      console.error("Error:", result.error);
      setIsStoryLoading(false)
    }
  }*/
/*
  const createStory = async (result) => {
    const { userId, shots } = result
    const portalRef = db.collection('portals').doc();
    const storyRef = db.collection(`users/${userId}/stories`).doc();

    const storyData = {
      description,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    await portalRef.set(storyData);
    await storyRef.set(storyData);

    const batch = db.batch();

    shots.forEach((shot, index) => {
      const shotRef = storyRef.collection('shots').doc();
      const takeRef = storyRef.collection('takes').doc();

      // Add shot to Firestore
      batch.set(shotRef, {
        shot: shot.shot,
        shotNumber: index + 1,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // Add take to Firestore
      batch.set(takeRef, {
        shotId: shotRef.id,
        takeDescription: "Auto-generated take",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    });

    await batch.commit(); // Commit the batch

    const generationsRef = db.collection(`users/${userId}/generations`);
    await generationsRef.add({
      prompt: description,
      gen_output: shots, // The entire shots array
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    console.log("Storyboard and generation stored successfully!");
  }*/

  const handleBlank = () => {
    const portalId = nanoid(12);
    handleContinueEmpty({dispatch:dispatch, portalId:portalId})
    history.push("/p/" + portalId);
  }

  const handleNew = (value) => {
    //setSelectedNew(e.target.value)
    switch(value) {
      case 'sample':
        createSample();
        break;
      case 'llm':
        handleStoryFromDescription();
        break;
      case 'import':
        handleStoryFromVideo();
        break;
      default:
        handleBlank();
        break; 
    }    
  }

  const handleContinueParts = () => {
    analytics.logEvent("BUTTON_CONTINUE_AFTER_PARTS");
    const parts = [
      { title: part1, order: story.parts[0].order },
      { title: part2, order: story.parts[1].order },
      { title: part3, order: story.parts[2].order },
    ];
    dispatch({ type: "UPDATE_STORY_PARTS", payload: parts });
    history.push("/p/" + portal_id);
  };
  
  const createSample = () => {
    const userRef = state.userData.ref
    const ownerIsForking = false
    const portalId = hostConfig.sample_story;
    const created = new Date()
    const newPortalId = nanoid(12);
    setNewSample(newPortalId);
    try {
      const newStory = {
          title: formatDate(created, 'title'),
          portal_id: newPortalId,
          created_at: created,
          last_cut: null,
      }
      dispatch({ type: "FORK_FROM_PORTAL", payload: {portalId:portalId, fork:newStory}})
    } catch(error) {
      toast.error(
          Messages.error.projectForkFailed,
      );
    }
  }

  /*useEffect(() => {
    selectedMethod.current = handleBlank;
  }, []);*/

  const CancelNewStoryAndContinue = () => {
    history.push("/storyboards");
  };

  function runTest() {
    alert("would test");
  }

  function renderPartText() {
    alert(`Part1 is ${part1}, Part2 is ${part2}, Part3 is ${part3}`);
    setPart1("a");
    setPart2("b");
    setPart3("c");
  }

  function handleUrlFormChange(e) {
    const url = e.target.value;
    const valid_start = "www.youtube.com/watch?v=";
    const valid_short_code_length = 11;
    if (url.includes(valid_start) && url.substr(url.lastIndexOf(valid_start) + valid_start.length).length === valid_short_code_length) {
      setUrlValid(true);
    } else {
      setUrlValid(false);
      //console.log("bad short code", url.substr(url.lastIndexOf(valid_start) + valid_start.length))
    }
    setImportUrl(url);
  }

  const importStoryboard = () => {};
  /*const handleContinueImport = () => {
    const portal_id = nanoid(12);
    const parts = [
      { title: "Storyboard", order: [] }
    ];
    dispatch({ type: "UPDATE_STORY_PARTS", payload: parts });
    history.push("/p/" + portal_id);
  };*/

  /*function importStoryboard() {
    if (!urlValid) return;

    const storyRef = state.stories.activeStory.ref;
    if (storyRef) {
      const created = new Date();
      const date =
        created.getHours() +
        ":" +
        (created.getMinutes() < 10 ? "0" + created.getMinutes() : created.getMinutes()) +
        " " +
        created.toLocaleString("default", { month: "short" }) +
        " " +
        created.getDate() +
        ", `" +
        created.getFullYear().toString().slice(2);
      const import_req_id = uuidv4(); // '1234' //
      const import_request = {
        type: "storyboard",
        url: importUrl,
        //"https://www.youtube.com/watch?v=aMlt1I-W5EM",
        // amknI7qr25g W86cTIoMv2U",
        // aMlt1I-W5EM  7 sec works
        // https://www.youtube.com/watch?v=qBNmY7S0BG8  12sec stop motion breaks
        // QpiGPLUCC3w
        // https://www.youtube.com/watch?v=adLGHcj_fmA   bruno
        //location: "the/user/the/story",
        title: date.toString(),
        created_at: created.getTime(),
        completed_at: null,
        deleted_at: null,
      };

      const importRequestRef = storyRef.collection("imports").doc(import_req_id);
      importRequestRef
        .set(import_request)
        .then(() => {
          dispatch({ type: "START_IMPORT", payload: {} });
          handleContinueImport();
        })
        .catch((error) => {
          console.log("Error creating import request:", import_request);
        });
      console.log("tried_import:", import_request);
    } else {
      console.log("no STORY, failed to test import request");
    }
  }*/

  /*function SuggestionButton() {
    return <ConfirmReplacementDialog name="Get Suggestion" title="Text replacement" desc="Replace current text with a randomized story idea?" doIfConfirmed={renderPartText} />;
  }*/

  //const alternate_form = <StoryPartForm addTask={renderPartText} init={importUrl} text={importUrl} set={setImportUrl} />;

  /*const alternate_buttons = (
    <div className="action_advance">
      <button type="button" className="btn" onClick={handleContinueEmpty}>
        {" "}
        Start empty{" "}
      </button>
      {"  "}
      <button type="button" className="btn__primary" onClick={handleContinueImport}>
        {" "}
        Create storyboard
      </button>
    </div>
  );*/

  /*const buttons_suggest_video = (
    <div className="action_advance">
      <Button color="primary" onClick={handleContinueEmpty}>
        Empty Storyboard
      </Button>
      {urlValid ? (
        <Button content="Create Storyboard" labelPosition="right" icon="checkmark" onClick={importStoryboard} positive />
      ) : (
        <Button content="Invalid" labelPosition="right" icon="close" onClick={handleContinueEmpty} negative />
      )}
    </div>
  );*/

  /*const create_board_from_video = (
    <div>
      <br />
      <div className="suggestion">
        <Typography variant="h3">Choose any video as a starting point.</Typography>
      </div>
      <br />
      <FormControl>
        <Input
          size="huge"
          id="form-input-youtube-url"
          control={Input}
          label="Youtube URL"
          value={importUrl}
          onChange={handleUrlFormChange}
          error={
            urlValid
              ? false
              : {
                content: "Please enter a valid url for a video.",
                pointing: "above",
              }
          }
        />
      </FormControl>
    </div>
  );*/

  const renderStoryBoardOptions = () => {
    switch (modalStep) {
      case 'video':
        return (
          <Box  sx={ formStyles }/* sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}*/>
            <Typography variant="p" sx={{ maxWidth:"400px", margin:"0 auto", padding:"20px 0"}}>Every scene from the first 60 seconds of a video will become a panel in your storyboard.</Typography>
            <DropUpload maxFiles={1} type="story-import-new" mediaRestrict={["video"]} />
          </Box>
        );
      case 'description':
        return (
          <Box sx={ formStyles }/* sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}*/>
            <Typography variant="h6">Generate a Storyboard from a description</Typography>
            <TextField
              multiline
              rows={3}
              fullWidth
              maxLength="1000"
              label="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ mt: 2 }}
            />
            <Box sx={{ width:"100%", display:"flex", alignItems:"center", justifyContent:"space-between" }}>
            <FormControlLabel
              sx={{ display: 'block' }}
              control={
                <Checkbox
                  checked={generateMedia}
                  onChange={handleGenerateMedia}
                  inputProps={{ 'aria-label': 'controlled' }}

                />
              }
              label="Generate Videos"
            />

            <Typography variant="span" sx={{ minHeight:"28px", color:theme.palette.primary.light, textAlign:"right" }}>{`Cost ${generateCost * 10}`}</Typography>
            </Box>
            {getWarning() && 
              <Box className="error">
                {getWarning()}
              </Box>
            }
            <Button 
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleGenerateStoryboard()
              }
            } variant="contained" sx={{ mt: 2, width: '100%' }}
              disabled={isStoryLoading || state.userData.credits - generate.cost < 0}
            >
              {
                isStoryLoading ?
                  <CircularProgress color="inherit" />
                  : <Box>
                    Generate Storyboard
                  </Box>
              }
            </Button>
          </Box>
        );
      default:
        return (
          <Box sx={newStyles}>
            <Box sx={ colStyles } value="sample" control={<Radio />}> 
              <Typography variant="h2">From A Sample</Typography>
              <Typography variant="p">Start with a sample project with shots and media.</Typography>
              <Button onClick={ ()=> { handleNew('sample') }} sx={{ marginTop:"25px" }} size="large" variant="contained">
                Create Sample
              </Button>
            </Box>
            <Box sx={ colStyles } value="new" control={<Radio />}>
              <Typography variant="h2">Start New</Typography>
              <Typography variant="p">Start with an empty storyboard for your new project.</Typography>
              <Button onClick={ ()=> { handleNew('blank') }} sx={{ marginTop:"25px" }} size="large" variant="contained">
                Create Blank
              </Button>
            </Box>
            <Box sx={ colStyles } value="llm" control={<Radio />}>
              <Typography variant="h2">From a Description</Typography>
              <Typography variant="p">Generate the start to your story using an LLM.</Typography>
              <Button onClick={ ()=> { handleNew('llm') }} sx={{ marginTop:"25px" }} size="large" variant="contained">
                Create Story
              </Button>
            </Box>
            <Box sx={ colStyles } value="import" control={<Radio />}>
              <Typography variant="h2">From a Video</Typography>
              <Typography variant="p">Create a new storyboard from an existing video.</Typography>
              <Button onClick={ ()=> { handleNew('import') }} sx={{ marginTop:"25px" }} size="large" variant="contained">
                Create Import
              </Button>
            </Box>
          </Box>
        );
    }
  }

  return (
    <>
      <Container className="has-footbar" style={{ maxWidth: '100%', paddingLeft: 0, paddingRight: 0 }}>
        <Headbar
          title="New Storyboard"
          editableTitle={false}
          share={false}
          fork={false}
          filmed={false}
          settings={null}
          starred={false}
          breadcrumbs={false}
        />
        <Box component="header" sx={{ display: "flex", padding: "0 1em", marginBottom:"20px", justifyContent: "space-between", borderBottom: "1px solid " + theme.palette.primary.main }}>
          {/*state.activeStory && Object.keys(state.activeStory).length && */}
          {/*stories.length > 1 && <Close onClick={CancelNewStoryAndContinue} sx={{ alignSelf: "center" }} />*/}
        </Box>
        <Box sx={{ textAlign: "center", display: "flex" }}>
          <Box sx={ wrapStyles }>
            <Typography variant="h3" sx={{ paddingTop:"50px", marginBottom: "50px" }}>
              How do you want to create your new storyboard?
            </Typography>
            {
              modalStep !== 'initial' ?
                <Box onClick={handleBack} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color: theme.palette.secondary.main, padding: '8px 0', }}>
                  <ArrowBack fontSize="small" />
                  <Box sx={{ fontSize: '18px' }}>Back</Box>
                </Box>
                : null}
            {renderStoryBoardOptions()}
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default NewProjectSelector;
